.toolbox-wrapper {
  border-bottom: 1px solid var(--orange);
}

.menu-wrapper {
  background-color: var(--orange);
}

.toolbox-logo-container {
  cursor: pointer;
}
.toolbox-logo {
  height: 50px;
}
