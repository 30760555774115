:root {
  --orange: #f7941e;
  --grey: #c5c6c8;
  --black: #000;
  --white: #fff;
  --red: #e94141;
  --green: #1ea51e;
  --green_transparent: #1ea51e7a;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.main-background {
  background-color: #000;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.mainbox-wrapper {
  background-color: var(--grey);
  margin: 20px 30px;
  max-height: 90%;
  overflow: auto;
}

.box-content-center {
  margin-right: auto;
  margin-left: auto;
  width: 90%!important;
}

.grey-background {
  background-color: var(--grey);
}

.fc-direction-ltr {
  direction: ltr;
  text-align: left;
  overflow: auto;
  height: 85vh;
}
.fc-event-main div {
  background-color: var(--orange);
}
.fc-header-toolbar {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}
.fc-button-primary {
  background-color: var(--black)!important;
  border: 1px solid var(--orange)!important;
}

.scrollable {
  overflow-y: scroll;
}
::-webkit-scrollbar {
  width: 5px;
}
::-webkit-scrollbar-track {
  boxShadow: inset 0 0 6px rgba(0,0,0,0.30);
  webkitBoxShadow: inset 0 0 6px rgba(0,0,0,0.30);
}
::-webkit-scrollbar-thumb {
  backgroundColor: var(--grey);
  outline: 1px solid var(--orange);
}

@media screen and (max-width: 1024px) {
  .mainbox-wrapper {
    margin: 10px 10px;
  }
}
